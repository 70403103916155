<template>
  <page>
    <page-section bottom-border>
      <page-title>
        <template #icon>
          <ic-document :size="32" />
        </template>
        <template #default> Invoices </template>
      </page-title>
    </page-section>

    <page-section bottom-border>
      <invoice-list />
    </page-section>

    <page-section bottom-border>
      <div class="flex flex-row gap-2">
        <ic-alert-circle
          :size="25"
          class="text-bb-mid-grey"
        />
        <p class="my-auto">Invoices are generated via <span class="text-bb-neon-purple">Fortnox</span></p>
      </div>
    </page-section>
  </page>
</template>

<script>
import IcDocument from 'vue-material-design-icons/FileDocument'
import IcAlertCircle from 'vue-material-design-icons/AlertCircle'
import InvoiceList from '@/components/account/InvoiceList'
import Page from '@/components/base/page/Page.vue'
import PageSection from '@/components/base/page/PageSection.vue'

export default {
  name: 'Invoices',
  components: { PageSection, Page, InvoiceList, IcDocument, IcAlertCircle },
}
</script>

<style scoped></style>
