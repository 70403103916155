<template>
  <div
    class="bb-loader"
    :class="{ white }"
  >
    <div></div>
    <div></div>
    <div></div>

    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: 'bb-loader',
  props: {
    white: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.bb-loader {
  $dot-size: 12px;
  $half-dot: calc(#{$dot-size} / 2);
  $animation-time: 1000ms;
  $delay-1: 0ms;
  $delay-2: 150ms;
  $delay-3: 300ms;
  $selected-bezier: cubic-bezier(0.33, -0.02, 0.68, 1.09);
  $container-width: 76px;
  $bb-gradient: linear-gradient(152.97deg, $bb-neon-pink -23.86%, rgba(240, 75, 165, 0) 103.93%), $bb-neon-purple;

  position: relative;
  width: $container-width;
  min-width: $container-width;
  height: 18px;

  &.white {
    div {
      background-color: white;
    }

    div:nth-child(6) {
      background: white;
    }

    div:nth-child(4) {
      background: white;
    }

    div:nth-child(5) {
      background: white;
    }
  }

  div {
    height: $dot-size;
    width: $dot-size;
    border-radius: 50%;
    background-color: $bb-neon-purple;
    position: absolute;
    margin-top: 3px;
    top: 0;
  }

  @keyframes resize {
    0% {
      transform: scale(1);
    }
    30% {
      transform: scale(1.3);
    }
    60% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes opacity {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  div:nth-child(3n) {
    left: $half-dot;
    animation: resize $animation-time $selected-bezier infinite forwards $delay-1;
  }

  div:nth-child(3n + 1) {
    $half-container: calc(#{$container-width} / 2);
    left: calc(#{$half-container} - #{$half-dot});
    animation: resize $animation-time $selected-bezier infinite forwards $delay-2;
  }

  div:nth-child(3n + 2) {
    right: $half-dot;
    animation: resize $animation-time $selected-bezier infinite forwards $delay-3;
  }

  div:nth-child(6) {
    background: $bb-gradient;
    animation:
      opacity $animation-time $selected-bezier infinite forwards $delay-1,
      resize $animation-time $selected-bezier infinite forwards $delay-1;
  }

  div:nth-child(4) {
    background: $bb-gradient;
    animation:
      opacity $animation-time $selected-bezier infinite forwards $delay-2,
      resize $animation-time $selected-bezier infinite forwards $delay-2;
  }

  div:nth-child(5) {
    background: $bb-gradient;
    animation:
      opacity $animation-time $selected-bezier infinite forwards $delay-3,
      resize $animation-time $selected-bezier infinite forwards $delay-3;
  }
}
</style>
