<template>
  <div
    class="page-section"
    :class="classes"
  >
    <div :class="{ 'max-w-content': !fullWidth }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageSection',
  props: {
    bottomBorder: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    noBottomPadding: {
      type: Boolean,
      default: false,
    },
    noTopPadding: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'no-bottom-padding': this.noBottomPadding,
        'bottom-border': this.bottomBorder,
        'no-top-padding': this.noTopPadding,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.page-section {
  @apply p-4;

  @screen s {
    @apply px-6 py-4;
  }
}

.bottom-border {
  @apply border-b border-bb-pale-grey;
}

.no-top-padding {
  @screen s {
    @apply pt-0;
  }
}

.no-bottom-padding {
  @screen s {
    @apply pb-0;
  }
}
</style>
