<template>
  <div>
    <div class="flex flex-row w-1/2 justify-between">
      <div class="flex flex-col">
        <p class="p2 mb-0">Company name</p>
        <p class="p2 font-semibold">{{ account.name }}</p>
      </div>
    </div>
    <table
      aria-describedby="Invoices"
      class="w-full"
    >
      <tr>
        <th scope="col">Invoice ID</th>
        <th scope="col">Billing profile</th>
        <th scope="col">Status</th>
        <th scope="col">Invoice created</th>
        <th scope="col">Due at</th>
        <th scope="col">Total amount</th>
        <th scope="col">Download PDF</th>
      </tr>
      <tr v-if="!loadingInvoices && invoices.length === 0">
        <td colspan="7">No invoices</td>
      </tr>
      <tr
        v-else
        v-for="invoice in invoices"
        :key="invoice.id"
      >
        <td>{{ invoice.id }}</td>
        <td>{{ billingProfile(invoice) }}</td>
        <td>{{ invoice.status }}</td>
        <td>{{ readableDate(invoice.created_at) }}</td>
        <td>{{ readableDate(invoice.due_at) }}</td>
        <td>{{ `${invoice.total} ${invoice.currency.code}` }}</td>
        <td>
          <a :href="sanitizedDownloadUrl(invoice.id)">
            <action-button type="download" />
          </a>
        </td>
      </tr>
      <tr v-if="invoicesError">
        <td colspan="7">
          <pre>{{ invoicesError }}</pre>
        </td>
      </tr>
      <tr v-if="loadingInvoices">
        <td colspan="7">
          <bb-loader />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BbLoader from '@/components/loader/BBLoader'
import dayjs from 'dayjs'
import ActionButton from '@/components/btn/ActionButton'

export default {
  name: 'invoice-list',
  components: { ActionButton, BbLoader },
  computed: {
    ...mapGetters({
      account: 'account/getAccount',
      billingById: 'billing/billingById',
      invoices: 'invoice/getInvoices',
      loadingInvoices: 'invoice/isInvoicesLoading',
      invoicesError: 'invoice/getInvoicesError',
    }),
  },
  methods: {
    ...mapActions({
      loadInvoicesIfNeeded: 'invoice/loadInvoicesIfNeeded',
    }),
    billingProfile(invoice) {
      return this.billingById(invoice.billing_id)?.name || 'N/A'
    },
    readableDate(unreadableDate) {
      if (unreadableDate) {
        return dayjs(unreadableDate).format('YYYY-MM-DD')
      } else {
        return 'N/A'
      }
    },
    sanitizedDownloadUrl(invoiceId) {
      return `${this.$http.defaults.baseURL}/invoice/${invoiceId}/download`
    },
  },
  beforeMount() {
    this.loadInvoicesIfNeeded()
  },
}
</script>

<style lang="scss" scoped>
th {
  text-align: start;
}

tr:nth-child(odd) {
  background-color: #f7f7f7;
}

th,
td {
  @apply py-4 pl-2 border border-bb-pale-grey;
}
</style>
